<template>
	<v-container class="site-width">
		<div v-if="loading" class="py-4 px-2">
			<v-progress-linear indeterminate color="#46C3B2" />
		</div>

		<div v-else-if="displayPage" class="hub-page-viewer" :class="isMobileView && 'hub-page-viewer--mobile'">
			<div v-if="displayManagerTools" class="page-manager-section">
				<p v-if="page.status" class="mb-0 text-center">
					<span v-if="!isMobileView">status: </span>
					<strong :style="managerToolsStatusColor">{{ page.status }}</strong>
				</p>
				<div v-if="displayManagerToolsActions" class="page-manager-section__actions">
					<v-btn v-if="!disableCopy" v-bind="managerToolsBtnProps" @click="copyPage">
						<v-icon>fas fa-copy</v-icon>
					</v-btn>
					<v-btn v-if="!disableEdit" v-bind="managerToolsBtnProps" @click="editPage">
						<v-icon>fas fa-pencil-alt</v-icon>
					</v-btn>
				</div>
            </div>

			<h3 class="page-title">{{ page.title || 'Title' }}</h3>

			<div class="page-description">
				<template v-if="description">
					<p v-for="paragraph in description" :key="paragraph">
						{{ paragraph }}
					</p>
				</template>
				<span v-else class="hub-pages__empty-item">
					{{ noDescriptionText }}
				</span>
			</div>

			<div class="page-categories">
				<template v-if="categories.length">
					<div v-if="categories.length > 1" class="page-categories-links">
						<a
							v-for="category in categories" :key="getIdentifier(category)"
							:href="`#${getIdentifier(category)}`"
							class="page-categories-link"
						>
							<span class="page-categories-link__content">{{ category.title }}</span>
						</a>
					</div>

					<div class="page-categories-wrapper">
						<CategoryViewer
							v-for="category in categories" :key="getIdentifier(category)"
							:id="getIdentifier(category)"
							:category="category"
						/>
					</div>
				</template>
				<span v-else class="hub-pages__empty-item">
					{{ noCategoriesText }}
				</span>
			</div>
		</div>

		<div v-else class="py-4 px-2">
			<v-alert text color="#46C3B2"><h5 class="mb-0">Page not found</h5></v-alert>
		</div>
	</v-container>
</template>

<script>
import CategoryViewer from './CategoryViewer.vue'
import hubPageMixin from '../mixins/hubPageMixin'
import { PAGE_VIEW_MODE, STATUS, getIdentifier, displayItemByStatus } from '../config/index'

export default {
	components: {
		CategoryViewer,
	},
	mixins: [hubPageMixin],
	props: {
		page: { type: Object, default: null },
		mode: { type: String, default: PAGE_VIEW_MODE.edit },
		disableCopy: { type: Boolean, default: false },
		disableEdit: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
	},
	data () {
		return {
			noDescriptionText: 'No description available',
			noCategoriesText: 'No categories available',
		}
	},
	computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		displayPage () {
			if (!this.page) { return false }
			if (this.hasManageContentPermission) { return true }
			return displayItemByStatus(this.page)
		},
		title () {
			return this.page?.title || 'Title'
		},
		description () {
			if (!this.page?.description) { return null }
			return this.page.description.split('\n')
		},
		categories () {
			if (!this.page?.categories?.length) { return [] }
			return this.page.categories
				.filter(displayItemByStatus)
				.filter((category) => {
					if (!category.topics?.length) { return false }
					return category.topics
						.filter(displayItemByStatus)
						.filter(this.displayItemByRoles)
						.filter(this.displayItemByPermissions)
						.length
				})
		},
		hasManageContentPermission () {
			return this.hasPermission(['manage:Content'])
		},
		displayManagerTools () {
			return this.hasManageContentPermission &&
				(this.displayManagerToolsActions || this.page?.status)
		},
		displayManagerToolsActions () {
			if (this.mode === PAGE_VIEW_MODE.view) { return false }
			return !this.disableCopy || !this.disableEdit
		},
		managerToolsBtnProps () {
			return this.isMobileView
				? { depressed: true, dark: true, icon: true, color: "#0083EB"}
				: { depressed: true, dark: true, fab: true, color: "#0083EB" }
		},
		managerToolsStatusColor () {
			const color = this.page.status === STATUS.publish
				? '#46C3B2'
				: (this.page.status === STATUS.draft) ? '#B3B330' : 'red'
			return { color }
		},
	},
	methods: {
		getIdentifier,
		copyPage () {
			this.$emit('copy-page', { ...this.page })
		},
		editPage () {
			this.$emit('edit-page', { ...this.page })
		},
	},
}
</script>

<style lang="scss">
html,
.v-dialog {
    scroll-behavior: smooth;
}
</style>

<style lang="scss" scoped>
@import '../style/style.scss';

.hub-page-viewer {
	--font-size: 1.625rem;
	--gap: 4rem;

	&.hub-page-viewer--mobile {
		--font-size: 1.25rem;
		--gap: 3rem;
	}

	.page-manager-section {
		position: absolute;
		right: 0.5rem;
		display: inline-flex;
		flex-direction: column;
		row-gap: 0.5rem;
		background-color: $color-white;
		padding: 0.25rem 0.5rem;
		border: thin solid $border-color;
		border-radius: 2px;

		&__actions {
			display: flex;
			justify-content: flex-end;
			column-gap: 0.5rem;
		}
	}

	.page-title {
		margin-bottom: 0;
		font-size: var(--font-size);
		line-height: 1.3;
		font-weight: 800;
	}

	.page-description {
		margin-top: 1rem;
		font-size: 1rem;
		line-height: 1.5;

		p {
			margin-bottom: 0 !important;
		}

		p + p {
			margin-top: 0.75rem !important;
		}
	}

	.page-categories {
		margin-top: 1.5rem;
	}

	.page-categories-links {
		display: flex;
		flex-wrap: wrap;
		gap: 1.5rem 2.5rem;
	}

	.page-categories-link {
		font-size: 1rem;
		line-height: 1.3;
		font-weight: 700;
		color: $color-brand-primary !important;
		transition: color 0.3s;

		&:hover {
			color: $color-brand-secondary !important;
		}
	}

	.page-categories-link__content {
		display: inline-block;
		padding-bottom: 1px;
		border-bottom: 2px solid currentColor;
	}

	.page-categories-wrapper {
		width: 100%;
		margin-top: 2rem;
		display: flex;
		flex-direction: column;
		row-gap: var(--gap);
	}
}
</style>
